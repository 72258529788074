* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.markdownWrapper {
  font-family: "Helvetica", sans-serif;

  p {
    margin-top: 0;
  }

  p,
  li,
  a,
  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  a {
    word-break: break-word;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 10px 0;
  }
}


